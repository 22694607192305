<template>
    <section class="app">
        <WebHeader active="news" />
        <section class="banner" />

        <div class="content">
            <h2>最新动态</h2>
            <p class="desc">官网只显示最近6月内的动态 更多动态请关注多源网络公众号</p>

            <div class="list">

                <div class="item">
                    <div class="time">09月28日</div>
                    <div class="info">
                        <h4>多源网络国庆假期安排</h4>
                        <div class="text">
                            <p>根据国务院办公厅通知精神，现将2022年国庆节放假安排通知如下：</p>
                            <p>10月1日（星期六）至7日（星期五）放假，共7天。10月8日（星期六）和10月9日（星期日）上班。</p>
                            <p>节假日期间，各区、各部门要妥善安排好值班，做好安全、保卫和疫情防控工作，遇有重大突发事件，要按规定及时报告并妥善处理。</p>
                            <p>按照市疫情防控指挥部要求，提前安排好工作生活，鼓励在津过节，减少人员聚集，做好个人防护，度过一个欢乐、祥和、平安的节日假期。</p>
                        </div>
                    </div>
                </div>
                

                <div class="item">
                    <div class="time">06月01日</div>
                    <div class="info">
                        <h4>多源网络端午假期安排</h4>
                        <div class="text">
                            <p>公司各部门：</p>
                            <p>2022年的端午节即将到来，根据“放假是为了让人们在休息身心的同时，更好的继承和发扬中国的传统佳节”的口号，同时结合我公司的实际情况，经我公司研究决定，作以下放假安排：</p>
                            <p>节放假3天，6月03日(星期五 端午当天)、6月04日(星期六)、6月05日(星期日)共放假三天，6月06日(星期一)正常上班。</p>
                            <p>节假日期间，各区、各部门要妥善安排好值班，做好安全、保卫和疫情防控工作，遇有重大突发事件，要按规定及时报告并妥善处理。</p>
                            <p>按照市疫情防控指挥部要求，提前安排好工作生活，鼓励在津过节，减少人员聚集，做好个人防护，度过一个欢乐、祥和、平安的节日假期。</p>
                            <p>同时祝愿大家端午安康</p>
                        </div>
                    </div>
                </div>


            </div>
        </div>

        <WebFooter></WebFooter>
    </section>
</template>


<style lang="scss" scoped>
.app {
    background: #f0f1f3;
    .banner {
        margin-top: -80px;
        width: 100%;
        background: #4dc56e;
        height: 300px;
    }

    .content {
        width: 1200px;
        min-height: 280px;
        margin: -150px auto 100px auto;
        position: relative;
        background: #fff;
        border-radius: 30px;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
        padding: 50px;

        h2{
            font-size: 40px;
            color: #333;
        }
        desc{
            font-size: 16px;
            color: #666;
        }

        .list{
            
            .item{
                display: flex;
                align-items: flex-start;
                padding: 30px 0;
                border-bottom: 1px solid #eee;
                .time{
                    width: 120px;
                    font-size: 16px;
                    color: #a3a3a3;
                    font-weight: bold;
                }

                .info{
                    flex: 1;
                    h4{
                        color: #1f1f1f;
                        font-weight: bold;
                        font-size: 16px;
                    }
                    .text{
                        color: #888;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
</style>


<script>
import WebHeader from "../../components/web-header.vue";
import WebFooter from "../../components/web-footer.vue";
export default {
    components: { WebHeader,WebFooter }
};
</script>